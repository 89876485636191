// import { screenView } from "src/func/native/event/evtTracking";
import { useEffect } from "react";
import { useLocation } from "react-router";

export const LocationListener = () => {
  const location = useLocation();

  useEffect(() => {
    const pathname = location.pathname === "/" ? "/main" : location.pathname;
    console.log(`You changed the page to: ${pathname + location.search}`);
    if (process.env.NODE_ENV === "production") {
      // screenView({ path: pathname, search: location.search });
    }
  }, [location.pathname, location.search]);

  return null;
};
