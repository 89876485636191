import { HistoryApiHandler } from "./HistoryAPI";
import { LocationListener } from "./Location";
import LogoutEventHandler from "./Logout";
import RepairEventHandler from "./Repair";

export const CustomEventListener = () => {
  return (
    <>
      <HistoryApiHandler />
      <LocationListener />
      <LogoutEventHandler />
      <RepairEventHandler />
    </>
  );
};
