import { MSHUTTLE_DATA } from "src/constants/addr";

interface IUserObject {
  language?: string; //"ko"
  id?: string;
  memberId?: string | null;

  profile?: { [camelCase: string]: string };
}

/**
 *   웹에서 실행 가능한지 판단
 */
const checkInit = () => {
  if (window.ChannelIO) {
    return true;
  } else {
    return false;
  }
};

/**
 *  채널 아이콘 노출
 */
const showIcon = async () => {
  console.log("showIcon");
  if (checkInit() === true) {
    window.ChannelIO(`showChannelButton`);
  }
};

/**
 *  채널 아이콘 숨김
 */
const hideIcon = async () => {
  console.log("hideIcon");
  if (checkInit() === true) {
    window.ChannelIO(`hideChannelButton`);
  }
};

/**
 *  채팅창 오픈
 */
const showMessenger = async () => {
  console.log("showMessenger");
  if (checkInit() === true) {
    window.ChannelIO("showMessenger");
  }
};

const hideMessenger = async () => {
  console.log("hideMessenger");
  if (checkInit() === true) {
    window.ChannelIO(`hideMessenger`);
  }
};

const openTalk = () => {
  if (MSHUTTLE_DATA.mobile === true) {
    window.open(`https://mshuttle.channel.io/support-bots/16498`);
  } else {
    showMessenger();
  }
};

/**
 * 채널 트래킹
 * @param action
 * @param event
 */
const event = async (action: string, event?: any) => {
  console.log("chEvent");

  if (checkInit() === true) {
    if (event !== undefined && event !== null) {
      await window.ChannelIO("track", action, event);
    } else {
      await window.ChannelIO("track", action);
    }
  }
};

const updateUser = async (userObject: IUserObject) => {
  if (checkInit() === true) {
    window.ChannelIO("updateUser", userObject);
  }
};

export const channelIO = {
  event,
  updateUser,
  showIcon,
  hideIcon,
  showMessenger,
  hideMessenger,
  openTalk,
};

// interface ChannelSetting {
//   pluginKey: String;
//   memberId?: String;
//   enabledTrackDefaultEvent: boolean;
//   enabledUtmSourceTrack: boolean;
//   locale: "ko";
//   profile?: {
//     mobileNumber?: String; // app
//     name?: String; // app
//     email?: String; // app
//     avatarUrl?: String; // app
//     CUSTOM_VALUE_1?: string;
//     ver?: string;
//   };
// }
