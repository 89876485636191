// import { ChannelIcon } from "src/components/Channel";
import AsyncErrorBoundary from "src/components/AsyncErrorBoundary";
import ErrorBoundary from "../ErrorBoundary";
import { ReactNode } from "react";
import { Helmet } from "react-helmet";
import { ChannelIcon } from "src/components/ChannelIO";
import { GnbTab } from "../components/Gnb";
import styled from "styled-components";

interface Props {
  title: string;
  channel: boolean;
  gnb: boolean;
  children: ReactNode;
}

export const Layout = ({ title, channel, gnb, children }: Props) => {
  return (
    <>
      <Helmet>
        <title>{title + " | 모두의셔틀"}</title>
      </Helmet>

      {/* 채널 */}
      <ChannelIcon view={channel} />

      {/* 바디 영역 */}
      <main>
        <ErrorBoundary>
          <AsyncErrorBoundary>
            <StChildren $show={gnb}>{children}</StChildren>
          </AsyncErrorBoundary>
        </ErrorBoundary>
      </main>

      {/* 네비게이션 탭 */}
      <GnbTab show={gnb} />
    </>
  );
};

export const StChildren = styled.div<{ $show: boolean }>`
  height: auto;
  /* background-color: var(--contents-secondary); */
  /* margin-bottom: ${(p) => (p.$show === true ? "60px" : null)}; */
`;
