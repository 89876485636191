import {
  Aside,
  Business,
  Caption1,
  CenterFlex,
  Home,
  MediaQuery,
  SafeAreaBottom,
  Search,
  contents,
  foundation,
  pointerEvt,
} from "ms-ui";
import styled from "styled-components";
import { MSHUTTLE_PATH } from "../../constants/addr";
import { useURI } from "ms_router";

interface IGnb {
  search: {
    click: () => void;
    "data-id": string;
  };
  home: {
    click: () => void;
    "data-id": string;
  };
  aside: {
    click: () => void;
    "data-id": string;
  };
  biz: {
    click: () => void;
    "data-id": string;
  };
}

const getColor = (active: boolean) => {
  return active === true
    ? "var(--foundation-normal-primary)"
    : "var(--colors-gray-300)";
};

export const GnbB = (props: IGnb) => {
  const { location } = useURI();

  /**
   *
   */
  const isActive = (type: "home" | "search" | "aside") => {
    if (type === "home") {
      return ["/", "/main"].includes(location.pathname);
    }
    if (type === "aside") {
      return location.pathname.startsWith("/aside");
    }
    if (type === "search") {
      return location.pathname.startsWith("/search");
    }
    return false;
  };

  return (
    <>
      <StyledGnb>
        <StyledGnbInner>
          {/* 왼쪽 */}
          <StyledGnbBtn
            $active={isActive("home")}
            onClick={(e) => props.home.click()}
            data-id={props.home["data-id"]}
          >
            <div>
              <Home color={getColor(isActive("home"))} />
              <div>홈</div>
            </div>
          </StyledGnbBtn>
          {/* 중앙 */}

          <StyledGnbBtn
            $active={false}
            data-id={props.biz["data-id"]}
            href={MSHUTTLE_PATH.biz}
          >
            <div>
              <Business color={getColor(false)} />
              <div>비즈니스</div>
            </div>
          </StyledGnbBtn>

          <StyledGnbBtn
            $active={isActive("search")}
            onClick={(e) => props.search.click()}
            data-id={props.search["data-id"]}
          >
            <div>
              <Search color={getColor(isActive("search"))} />
              <div>검색</div>
            </div>
          </StyledGnbBtn>
          {/* 오른쪽 */}
          <StyledGnbBtn
            $active={isActive("aside")}
            onClick={(e) => props.aside.click()}
            data-id={props.aside["data-id"]}
          >
            <div>
              <Aside color={getColor(isActive("aside"))} />
              <div>더 보기</div>
            </div>
          </StyledGnbBtn>
        </StyledGnbInner>
      </StyledGnb>
    </>
  );
};

const StyledGnb = styled.div`
  ${MediaQuery}
  position: fixed;
  bottom: 0px;
  z-index: 1030;

  width: 100%;

  background-color: ${contents.secondary.value};
  box-shadow: 0 1px ${contents.tertiary.value} inset;

  display: flex;
  align-items: center;
  height: calc(60px + env(safe-area-inset-bottom));
  ${SafeAreaBottom()}
`;

const StyledGnbInner = styled.div`
  width: 100%;
  height: 100%;
  ${CenterFlex}
`;

const StyledGnbBtn = styled.a<{ $active: boolean }>`
  height: 100%;
  width: 100%;
  cursor: pointer;
  text-align: center;
  ${Caption1}
  ${CenterFlex}
  
  color: ${(p) =>
    p.$active === true
      ? foundation.normal.primary.value
      : contents.accent.value};

  font-weight: ${(p) => (p.$active === true ? "bolder" : "normal")};

  ${(p) => pointerEvt(p["data-id"])};
`;
