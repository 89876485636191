export const initCustomHistoryApi = () => {
  let pushStateScript: HTMLScriptElement | null = document.querySelector(
    `script[id="pushstate"]`
  );

  let replaceStateScript: HTMLScriptElement | null = document.querySelector(
    `script[id="replacestate"]`
  );

  if (!pushStateScript) {
    pushStateScript = document.createElement("script");
    pushStateScript.id = "pushstate";
    pushStateScript.text = `
          (function () {
            var _pushState = history.pushState;
            history.pushState = function(state, title, url) {
              _pushState.call(this, state, title, url);
              window.dispatchEvent(new CustomEvent("pushstate", { detail: { title, url, state } }));
            };
          })();`;

    // this.remove();`;
    document.head.appendChild(pushStateScript);
  }

  if (!replaceStateScript) {
    replaceStateScript = document.createElement("script");
    replaceStateScript.id = "replacestate";
    replaceStateScript.text = `
          (function () {
            var _replaceState = history.replaceState;
            history.replaceState = function (state, title, url) {
              _replaceState.call(this, state, title, url);
              window.dispatchEvent(new CustomEvent("replacestate", { detail: { title, url, state } }));
            };
          })();`;

    // this.remove();`;
    document.head.appendChild(replaceStateScript);
  }
};
