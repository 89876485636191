import * as React from "react";

import { channelIO } from "src/func/tracking/channel";

export const ChannelIcon: React.FC<{ view: boolean | undefined }> = ({
  view,
}) => {
  React.useEffect(() => {
    const findCh = () =>
      requestAnimationFrame(() => {
        console.log("view: ", view);

        if (view) {
          channelIO.showIcon();
        } else {
          channelIO.hideIcon();
        }
      });

    findCh();
  }, []);

  return null;
};
