import { MSHUTTLE_PATH, ms_file } from "src/constants/addr";
import { Body1, Body2, Button, CenterFlex, Nav, Paragraph } from "ms-ui";
import { TSpacing } from "ms-ui/dist/types/DesignToken/interface/font";
import styled from "styled-components";

const ErrorAlert = () => {
  return (
    <>
      <Nav
        right={[
          {
            type: "home",
            onClick: () => window.location.replace(`${MSHUTTLE_PATH.main}`),
          },
        ]}
      />
      <Paragraph>
        <FlexBox $marginTop="40">
          <AlertType>
            <img src={`${ms_file}/image/icon/warning.svg`} alt={"warning"} />
          </AlertType>
          <StyledContent>
            <div>페이지를 찾을 수 없습니다.</div>
            <>
              이용에 불편을 드린 점 진심으로 사과드리며,
              <br />
              편리하게 서비스를 이용하실 수 있도록
              <br />
              최선의 노력을 다하겠습니다.
            </>
          </StyledContent>
          <ButtonBox>
            <Button
              width="100%"
              radius={"5"}
              color={`primary`}
              size="rg"
              onClick={() => window.location.replace(`${MSHUTTLE_PATH.main}`)}
            >
              모두의셔틀 홈으로
            </Button>
          </ButtonBox>
        </FlexBox>
      </Paragraph>
    </>
  );
};

export default ErrorAlert;

const AlertType = styled.div`
  margin: 0 auto;
  width: 50px;
  height: 50px;
  border-radius: 25px;
  background-color: #ffd800;
  margin-bottom: var(--spacing-16);
  ${CenterFlex}
`;

const FlexBox = styled.div<{ $marginTop?: TSpacing; marginBottom?: TSpacing }>`
  position: relative;
  ${(p) =>
    p.$marginTop ? `margin-top: var(--spacing-${p.$marginTop})` : null};
  ${(p) =>
    p.marginBottom ? `margin-bottom: var(--spacing-${p.marginBottom})` : null};

  width: 100%;
  height: calc(100dvh - 57px);
  padding: 0 0;
`;

const StyledContent = styled.div`
  ${Body1}
  width: 100%;
  text-align: center;
  white-space: pre-wrap;

  color: var(--colors-gray-400);
  margin-bottom: var(--spacing-16);
`;

const ButtonBox = styled.div`
  display: flex;
  flex-direction: column;
  button {
    margin-bottom: 12px;
  }
`;

// 컨텐트
export const UIContent = styled.div`
  width: 100%;
  display: flex;
  margin-bottom: var(--spacing-16);
  &:last-child {
    margin-bottom: 0;
  }
`;

export const UIKey = styled.div`
  ${Body2}
  display: flex;
  color: var(--colors-gray-300);
  width: 88px;
  flex-basis: 88px;
  flex-shrink: 0;
  margin-right: var(--spacing-24);
`;

export const UIValue = styled.div`
  width: calc(100% - var(--spacing-24) - 88px);
  ${Body2}
  color: var(--colors-gray-500);
  text-align: left;
`;
