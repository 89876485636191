import { useURI } from "ms_router";
import { useEffect } from "react";
import { useStores } from "src/store/global";

const LogoutEventHandler = () => {
  const { navigate } = useURI();
  const { authStore } = useStores();

  const listener = (e) => {
    authStore.logout();
    navigate("/", { replace: true });
  };

  useEffect(() => {
    window.addEventListener("logout-event", listener as EventListener);

    return () => {
      window.removeEventListener("logout-event", listener as EventListener);
    };
  }, []);

  return null;
};

export default LogoutEventHandler;
