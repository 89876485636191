import moment, { unitOfTime } from "moment-timezone";
export type Moment = moment.Moment;
export function momentTz(date?: string | number) {
  return moment(date).tz("Asia/Seoul");
}
/**
 * 오늘의 마지막 시간을 반환한다. 타입은 Date 타입
 */
export const getEndOfToday = () => {
  return momentTz().endOf("day").toDate();
};

/**
 * 오늘 기준 차이나는 일자 계산
 * @param day
 * @returns
 */
export const intervalDay = (day: number | string) => {
  const date = momentTz(day).diff(new Date(), "days");
  return date;
};
