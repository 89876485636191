import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { GlobalFont, GlobalStyle } from "ms-ui";
import App from "./App";

const startApp = () => {
  const rootElement = document.getElementById("root");
  if (!rootElement) throw new Error("Failed to find the root element");

  const root = ReactDOM.createRoot(rootElement);

  root.render(
    <BrowserRouter>
      <GlobalFont />
      <GlobalStyle />
      <App />
    </BrowserRouter>
  );
};

//initChannel();
startApp();
