import loadable from "@loadable/component";
import { ModalLoader } from "ms-ui";

const LoadComp = (loader: () => Promise<any>) => {
  return loadable(loader, { fallback: <ModalLoader active={true} /> });
};

const Main = LoadComp(
  () => import(/* webpackPrefetch: true */ "../pages/Main")
);
const Search = LoadComp(
  () => import(/* webpackPrefetch: true */ "../pages/Search")
);
const Mypage = LoadComp(
  () => import(/* webpackPrefetch: true */ "../pages/Mypage/Aside")
);
const MypageApply = LoadComp(
  () => import(/* webpackPrefetch: true */ "../pages/Mypage/Apply")
);
const MypageApplyDetail = LoadComp(
  () => import(/* webpackPrefetch: true */ "../pages/Mypage/ApplyDetail")
);
const MypageMyinfo = LoadComp(
  () => import(/* webpackPrefetch: true */ "../pages/Mypage/MyInfo")
);
const Coupon = LoadComp(
  () => import(/* webpackPrefetch: true */ "../pages/Mypage/Coupon")
);
const Intro = LoadComp(
  () => import(/* webpackPrefetch: true */ "../pages/Intro")
);
const Error = LoadComp(
  () => import(/* webpackPrefetch: true */ "../pages/Error")
);
const InviteMain = LoadComp(
  () => import(/* webpackPrefetch: true */ "../pages/Invite/Main")
);
const InviteRoute = LoadComp(
  () => import(/* webpackPrefetch: true */ "../pages/Invite/Route")
);
const LeaveComplete = LoadComp(
  () => import(/* webpackPrefetch: true */ "../pages/Leave")
);
const Repair = LoadComp(
  () => import(/* webpackPrefetch: true */ "../pages/Repair")
);

const Routes = [
  {
    path: "/",
    name: "자면서 출근하는 나만의 셔틀",
    component: Main,
    auth: false,
    gnb: true,
    ch: true,
  },
  {
    path: "/main",
    name: "자면서 출근하는 나만의 셔틀",
    component: Main,
    auth: false,
    gnb: true,
    ch: true,
  },
  {
    path: "/search/*",
    component: Search,
    name: "내 출근길 찾기",
    auth: false,
    gnb: false,
    ch: false,
  },
  {
    path: "/aside",
    name: "더 보기",
    component: Mypage,
    gnb: true,
    ch: true,
  },
  {
    path: "/mypage/Apply",
    auth: true,
    name: "신청내역",
    component: MypageApply,
    ch: true,
    gnb: false,
  },
  {
    path: "/mypage/ApplyDetail",
    auth: true,
    name: "탑승정보 상세",
    component: MypageApplyDetail,
    ch: true,
    gnb: false,
  },
  {
    path: "/mypage/Myinfo",
    auth: true,
    name: "내 정보",
    component: MypageMyinfo,
    ch: true,
    gnb: false,
  },
  {
    path: "/mypage/Coupon",
    auth: true,
    name: "쿠폰함",
    component: Coupon,
    ch: true,
    gnb: false,
  },
  {
    path: "/intro",
    name: "회사소개",
    component: Intro,
    gnb: false,
  },
  {
    path: "/error",
    name: "에러",
    component: Error,
    ch: true,
    gnb: false,
  },

  {
    path: "/invite/route",
    name: "초대장",
    component: InviteRoute,
    ch: false,
    gnb: false,
  },
  {
    path: "/invite/main",
    name: "초대장",
    component: InviteMain,
    ch: false,
    gnb: false,
  },
  {
    path: "/comebackPlz",
    name: "탈퇴 완료",
    component: LeaveComplete,
    ch: true,
    gnb: false,
  },
  {
    path: "/repair",
    name: "점검 중",
    auth: false,
    component: Repair,
    ch: true,
    gnb: false,
  },
];

export default Routes;
