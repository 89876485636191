import { Route, Routes } from "react-router-dom";
import { observer } from "mobx-react";
import { AppContainer } from "ms-ui";
import routes from "./routes";
import loadable from "@loadable/component";
import withAuth from "./hoc/withAuth";
import { CustomEventListener } from "./components/CustomEventListener";
import Background from "./components/Background";
import { MSHUTTLE_DATA } from "./constants/addr";

function App() {
  return (
    <AppContainer>
      {/* 커스텀 이벤트 리스너 */}
      <CustomEventListener />

      {/* background */}
      {!MSHUTTLE_DATA.mobile && <Background />}

      {/* 라우트 */}
      <Routes>
        {routes.map((item, idx) => {
          const { path, auth, ch, gnb, name, component } = item;
          return (
            <Route
              key={idx}
              path={`${path}`}
              Component={withAuth({
                LoadableComp: component,
                title: name,
                authentication: auth ? auth : false,
                channel: ch ? ch : false,
                gnb,
              })}
            />
          );
        })}

        {/* 에러 라우트는 마지막에 */}
        <Route Component={loadable(() => import("./pages/Error"))} />
      </Routes>
    </AppContainer>
  );
}

export default observer(App);
