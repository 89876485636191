import { action, computed, makeObservable, observable, toJS } from "mobx";

export default class RepairStore {
  _isRepair: boolean = false;
  _schedule?: { start?: string; end?: string };

  constructor() {
    makeObservable(this, {
      _isRepair: observable,
      isRepair: computed,
      setIsRepair: action,

      _schedule: observable,
      schedule: computed,
      setSchedule: action,
    });
  }

  get isRepair() {
    return toJS(this._isRepair);
  }
  setIsRepair(rp: boolean) {
    this._isRepair = rp;
  }

  get schedule() {
    return toJS(this._schedule);
  }
  setSchedule(s?: { start?: string; end?: string }) {
    this._schedule = s;
  }
}
