import { useEffect } from "react";
import { initCustomHistoryApi } from "../Scripts/HistoryAPI";

//next에서는 pushState/replaceState가 nextjs 바깥에서 불렸을 때,
export const HistoryApiHandler = () => {
  const popstateHandler = (event: any) => {
    console.log("popstate", event);
    // historyStackStore.pop();
  };

  const pushstateHandler = (e: any) => {
    console.log("pushstate", e);
    // historyStackStore.push(e.detail);
    // transitionTo("left");
  };
  const replacestateHandler = (e: any) => {
    console.log("replacestate", e);
    // historyStackStore.replace(e.detail);
  };

  useEffect(() => {
    initCustomHistoryApi();

    window.addEventListener("popstate", popstateHandler);
    window.addEventListener("pushstate", pushstateHandler);
    window.addEventListener("replacestate", replacestateHandler);

    return () => {
      window.removeEventListener("popstate", popstateHandler);
      window.removeEventListener("pushstate", pushstateHandler);
      window.removeEventListener("replacestate", replacestateHandler);
    };
  }, []);
  return null;
};
