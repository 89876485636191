import React from "react";
import styled from "styled-components";
import { Coupon } from "./Coupon";
import { Logo } from "src/pages/Main/views/MainSection/logo";
import { Body1, Header1, Header3 } from "ms-ui";

export const MsContent = () => {
  return (
    <StMsContent>
      <StMsContentLogo>
        <Logo />
      </StMsContentLogo>
      <StMsContentQuestion>
        <StMsContentQuestionTxt>
          얼마나 편한지 궁금한가요?
        </StMsContentQuestionTxt>
        <div></div>
      </StMsContentQuestion>

      <StMsContentFree>
        2일 <span>무료체험 가능!</span>
      </StMsContentFree>

      <StMsContentFreeDetail>
        결제없이 무료로 체험해보세요!
        <br />
        결정은 나중에 해도 괜찮아요!
      </StMsContentFreeDetail>

      <StMsContentFreeCoupon>
        <Coupon />
      </StMsContentFreeCoupon>
    </StMsContent>
  );
};

const StMsContent = styled.div``;

const StMsContentLogo = styled.div`
  margin-bottom: var(--spacing-24);
`;

const StMsContentQuestion = styled.div`
  display: flex;
  margin-bottom: var(--spacing-24);
`;

const StMsContentQuestionTxt = styled.div`
  ${Header3}
  color: var(--colors-gray-500);
  border-bottom: 2px solid var(--colors-mainblue-500);
`;

const StMsContentFree = styled.div`
  ${Header1}
  color: var(--colors-gray-500);
  margin-bottom: var(--spacing-12);
  span {
    color: var(--colors-mainblue-500);
  }
`;

const StMsContentFreeDetail = styled.div`
  ${Body1}
  color: var(--colors-gray-500);
  margin-bottom: 52px;
`;

const StMsContentFreeCoupon = styled.div``;
