import { errorEvt } from "src/func/customEvent/error";
import { useEffect } from "react";
import EventEmitter from "src/func/customEvent/EventEmitter";

/**
 * 브라우저 환경에선 에러가 발생했는데 .catch가 없으면 unhandledrejection 핸들러가 트리거 됩니다.
 * 대개 이런 에러는 회복할 수 없기 때문에 개발자로서 할 수 있는 최선의 방법은 사용자에게 문제 상황을 알리고 가능하다면 서버에 에러 정보를 보내는 것입니다.
 */
const AsyncErrorBoundary = ({ children }: any) => {
  /**
   * 받은 에러를 핸들링해서 사용자에게 알리고, 서버에 에러 로그를 보낸다.
   */
  const captureError = (e: PromiseRejectionEvent) => {
    e.preventDefault();
    console.log(e.reason);

    if (e.reason instanceof Error) {
      //서버로 에러 로그를 보냄
      EventEmitter.emit(errorEvt(e.reason.name, e.reason.message));

      window.alert("잠시 후 다시 시도해주세요!");
    }
  };

  useEffect(() => {
    window.addEventListener("unhandledrejection", captureError);
    return () => {
      window.removeEventListener("unhandledrejection", captureError);
    };
  }, []);

  return children;
};

export default AsyncErrorBoundary;
