export const MSHUTTLE_DATA = window.__MSHUTTLE__DATA__;
export const MSHUTTLE_PATH = window.__MSHUTTLE__PATH__;
export const MSHUTTLE_ASSETS = window.__MSHUTTLE__PATH__
  ? `${window.__MSHUTTLE__PATH__.static_resource}/assets`
  : "";

// export const msfile = `https://msfile.s3.ap-northeast-2.amazonaws.com`;
export const ms_file =
  MSHUTTLE_DATA.stage === "production"
    ? `https://s3.ap-northeast-2.amazonaws.com/ms-file/prod`
    : `https://s3.ap-northeast-2.amazonaws.com/ms-file/dev`;
