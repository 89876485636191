/**
 * 로컬스토리지 사용가능한지 검사
 * 참조 https://developer.mozilla.org/ko/docs/Web/API/Web_Storage_API/Using_the_Web_Storage_API
 */
export function localStorageAvailable() {
  const storage = window.localStorage;
  try {
    const x = "__storage_test__";
    storage.setItem(x, x);
    storage.removeItem(x);
    return true;
  } catch (e) {
    return false;
  }
}

/**
 * 로컬스터리지 remove
 * @param key
 */
export const removeLS = (key: string) => {
  if (localStorageAvailable()) {
    return localStorage.removeItem(key);
  } else {
    console.error("local storage disabled");
    return null;
  }
};

/**
 * 로컬스토리지 get
 * @param key 가져올 값의 키
 */
export const getLS = (key: string) => {
  if (localStorageAvailable()) {
    return localStorage.getItem(key);
  } else {
    console.error("local storage disabled");
    return null;
  }
};

/**
 * 로컬스토리지 set
 * @param key 수정할 값의 키
 * @param data 저장할 데이터
 */
export const setLS = (key: string, data: any) => {
  if (localStorageAvailable()) {
    localStorage.setItem(
      key,
      typeof data === "string" ? data : JSON.stringify(data)
    );
  } else {
    console.error("local storage disabled");
  }
};
