import React from "react";

import { AuthStore } from "./AuthStore";
import RepairStore from "./RepairStore";

export class RootStore {
  authStore: AuthStore;
  repairStore: RepairStore;

  constructor() {
    this.authStore = new AuthStore();
    this.repairStore = new RepairStore();
  }
}

const StoresContext = React.createContext(new RootStore());

export const useStores = () => React.useContext(StoresContext);
