import {
  action,
  computed,
  makeObservable,
  observable,
  reaction,
  toJS,
} from "mobx";
import { CommonStore, getErrorMsg } from "ms-ui";
import { IUser } from "ms_data";
import { cookie } from "ms-ui";
import { momentTz } from "src/func/moment";
import { channelIO } from "src/func/tracking/channel";

export class AuthStore extends CommonStore {
  _user: Partial<IUser> | undefined = undefined;
  _sessionId: string | undefined = undefined;
  _expTime: string | undefined = undefined;
  _isBoarding: boolean = false;

  constructor() {
    super();
    makeObservable(this, {
      _user: observable,
      user: computed,
      setUser: action,

      _sessionId: observable,
      sessionId: computed,
      setSessionId: action,

      _isBoarding: observable,
      isBoarding: computed,
      setIsBoarding: action,

      _expTime: observable,
      expTime: computed,
      setExpTime: action,
      isExpired: computed,

      logout: action,
    });

    reaction(
      () => this.sessionId,
      (curr, prev) => {
        if (!prev && curr) {
          channelIO.event("react_ready", {
            msuw_at: curr,
            timestamp: new Date().getTime(),
          });
        }
      }
    );
  }

  get user() {
    return toJS(this._user);
  }
  setUser(user: Partial<IUser>) {
    this._user = user;
  }

  get sessionId() {
    return toJS(this._sessionId);
  }
  setSessionId(sid: string) {
    this._sessionId = sid;
  }

  get isBoarding() {
    return toJS(this._isBoarding);
  }
  setIsBoarding(ib: boolean) {
    this._isBoarding = ib;
  }

  get isLoading() {
    return toJS(this._loading);
  }

  get expTime() {
    return toJS(this._expTime);
  }
  setExpTime(expTime: string | undefined) {
    this._expTime = expTime;
  }
  get isExpired() {
    if (!this.expTime) return true;
    const now = momentTz();
    if (this.expTime <= now.toISOString()) return true;
    return false;
  }

  /**
   * 로그아웃
   */
  logout() {
    try {
      this._loading = true;
      if (cookie.get("MSUW_SES")) cookie.remove("MSUW_SES");
      this._user = undefined;
      this._isBoarding = false;
      try {
        window.dataLayer.push({ userId: null });
        window.__MSHUTTLE__DATA__.user = undefined;
        channelIO.updateUser({ memberId: null });
      } catch (e) {
        console.log(e);
      }
    } catch (e: any) {
      this._alert = {
        msg: getErrorMsg(e),
        open: true,
        fn: () => {},
      };
    } finally {
      this._loading = false;
    }
  }
}
