import { useURI } from "ms_router";
import { useEffect } from "react";
import { GnbB } from "./GnbB";
import { channelIO } from "src/func/tracking/channel";
import SearchRouteMapUrl from "../../routes/search";
import { gnbEvtId } from "src/constants/evt/gnb";
import { setLS } from "../../func/storage";

interface IProps {
  show: boolean;
}

export const GnbTab = ({ show }: IProps) => {
  const { navigate } = useURI();

  useEffect(() => {
    if (show) {
      showTab();
    }

    if (!show) {
      hideTab();
    }
  }, [show]);

  const showTab = () => {
    document.body.classList.add("has-tab");
  };

  const hideTab = () => {
    document.body.classList.remove("has-tab");
  };

  return (
    <>
      {show && (
        <GnbB
          search={{
            click: () => {
              channelIO.event("goSearchResult", {});
              setLS("MAIN_SEARCH_TOOTIP_CLOSED", true);
              navigate(SearchRouteMapUrl.subPath("start_form"));
            },
            ["data-id"]: "GNB_search",
          }}
          biz={{
            click: () => {
              if (!["/", "/main"].includes(window.location.pathname)) {
                navigate(`/`);
              }
            },
            ["data-id"]: "GNB_biz",
          }}
          home={{
            click: () => {
              if (!["/", "/main"].includes(window.location.pathname)) {
                channelIO.event("goHome", {});
                navigate(`/`);
              }
            },
            ["data-id"]: "GNB_home",
          }}
          aside={{
            click: () => {
              if (window.location.pathname !== "/aside") {
                navigate(`/aside`);
              }
            },
            ["data-id"]: "GNB_aside",
          }}
        />
      )}
    </>
  );
};
