class SearchRouteMapUrl {
  PATH = "/search";

  private SUB_PATH = {
    start_map: "/start_map",
    end_map: "/end_map",
    start_form: "/start_form",
    end_form: "/end_form",
    time_form: "/select_time",
    result: "/select_path_map",
  };

  subPath = (sub: SubPathType) => {
    return `${this.PATH}${this.SUB_PATH[sub]}`;
  };

  get allPath() {
    return {
      RESULT: this.subPath("result"),
      START_FORM: this.subPath("start_form"),
      END_FORM: this.subPath("end_form"),
      START_MAP: this.subPath("start_map"),
      END_MAP: this.subPath("end_map"),
      TIME_FORM: this.subPath("time_form"),
    };
  }
}
export default new SearchRouteMapUrl();

type SubPathType =
  | "start_map"
  | "end_map"
  | "start_form"
  | "end_form"
  | "result"
  | "time_form";
