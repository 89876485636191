import { cookie } from "ms-ui";
import { isNil } from "ms_data";
import { make_uuid_v1 } from "../uuid.utils";

export const getSessionId = () => {
  let SESSION_ID = cookie.get("MSUW_AT");
  if (isNil(SESSION_ID)) {
    SESSION_ID = make_uuid_v1();
    cookie.set("MSUW_AT", SESSION_ID);
  }

  return SESSION_ID;
};
