import styled from "styled-components";
import { MsContent } from "./Content";

const file = "https://s3.ap-northeast-2.amazonaws.com/msfile/";

// pc 전용 백그라운드
const Background = () => {
  return (
    <AppBackground>
      <AppBackgroundBottom></AppBackgroundBottom>
      <AppBackgroundLeft>
        <AppContent>
          <AppContentCtn>
            <MsContent />
          </AppContentCtn>
        </AppContent>
        <AppBuilding1>
          <img src={`${file}user/Building1.svg`} alt="" />
        </AppBuilding1>
      </AppBackgroundLeft>
      <AppBackgroundRight>
        <AppBuilding2>
          {/* <Building2 /> */}
          <img src={`${file}user/Building2.svg`} alt="" />
        </AppBuilding2>
      </AppBackgroundRight>
    </AppBackground>
  );
};

const AppBackground = styled.div`
  width: 100%;
  height: 100vh;
  background-color: var(--colors-mainblue-050);
  position: fixed;
  z-index: -1;
  left: 0;
`;

const AppBackgroundBottom = styled.div`
  background-color: var(--foundation-normal-primary);
  width: 100%;
  height: 50px;
  position: fixed;
  bottom: 0;
  z-index: -1;

  @media (max-width: 1024px) {
    display: none;
  }
`;

const AppBackgroundLeft = styled.div`
  width: calc((100% - 420px) / 2);
  height: 100%;
  position: fixed;
  /* bottom: 50px; */
  left: 0;

  @media (max-width: 1024px) {
    display: none;
  }
`;

const AppContent = styled.div`
  position: relative;
  width: 100%;
`;

const AppContentCtn = styled.div`
  position: absolute;
  top: 180px;
  right: 107px;

  @media (max-width: 1200px) {
    right: 98px;
  }
`;

const AppBuilding1 = styled.div`
  position: relative;
  width: 100%;
  height: 100%;

  img {
    position: absolute;
    bottom: 50px;
    right: 80px;
    @media (max-width: 1200px) {
      right: 69px;
    }
  }
`;

const AppBackgroundRight = styled.div`
  width: calc((100% - 420px) / 2);
  height: 100%;
  position: fixed;
  /* bottom: 50px; */
  right: 0;

  @media (max-width: 1024px) {
    display: none;
  }
`;

const AppBuilding2 = styled.div`
  position: relative;
  width: 100%;
  height: 100%;

  img {
    position: absolute;
    bottom: 50px;
    left: 92px;
    @media (max-width: 1200px) {
      right: 80px;
    }
  }
`;

const StMsContent = styled.div``;

export default Background;
