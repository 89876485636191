import React, { ErrorInfo } from "react";
import ErrorAlert from "./pages/Error";
import { MSHUTTLE_DATA, MSHUTTLE_PATH } from "./constants/addr";
import axios from "axios";

interface Props {
  children: React.ReactNode;
}

interface State {
  hasError: boolean;
}

class ErrorBoundary extends React.Component<Props, State> {
  state = {
    hasError: false,
  };

  static getDerivedStateFromError(error: Error): State {
    return { hasError: true };
  }

  componentDidCatch(error: Error, info: ErrorInfo) {
    console.log(error);
    if (process.env.NODE_ENV === "production") {
      // channelIO.event("Error", { info });

      axios({
        url: `${MSHUTTLE_PATH.common_resource}/error`,
        method: "POST",
        data: {
          error: {
            error_name: error.toString(),
            error_msg: info.componentStack ?? "error_boundary",
          },
          mshuttle_data: {
            ver: MSHUTTLE_DATA.ver,
            stage: MSHUTTLE_DATA.stage,
            referer: window.location.href,
            userId: MSHUTTLE_DATA.user?.userId,
          },
        },
        withCredentials: true,
        headers: {
          "Content-Type": `application/json`,
        },
      });
    }

    this.setState({
      hasError: true,
    });
  }

  render() {
    if (this.state.hasError) {
      return <ErrorAlert />;
    }
    return this.props.children;
  }
}

export default ErrorBoundary;
