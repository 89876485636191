import { useEffect, useState } from "react";
import { observer } from "mobx-react";

import { LoadableClassComponent } from "@loadable/component";

import { Layout } from "./Layout";
import { useStores } from "src/store/global";

import { Redirect } from "ms_router";
import { cookie } from "ms-ui";
import { momentTz } from "src/func/moment";
import { get_auth } from "src/repository/Member/auth";
import { isNil } from "ms_data";
import { getSessionId } from "src/func/channel";

interface Parameter {
  LoadableComp: LoadableClassComponent<any>;
  title: string;
  authentication: boolean;
  channel: boolean;
  gnb: boolean;
}

const withAuth = ({
  LoadableComp,
  title,
  authentication = false,
  channel = false,
  gnb,
}: Parameter) => {
  const AuthenticationCheck = (props: any) => {
    const { authStore, repairStore } = useStores();
    const { user, sessionId } = authStore;
    const [isLoading, setLoading] = useState(true);

    /** */
    useEffect(() => {
      let canceler;
      (async () => {
        try {
          /** channel 세션 id */
          if (isNil(sessionId)) {
            const sid = getSessionId();
            authStore.setSessionId(sid);
          }

          const { user, isExpired } = authStore;
          /** 애초에 쿠키가 없다면 STOP */
          if (!cookie.get("MSUW_SES")) return;
          /** 유저객체가 이미 있고, 만료시간 전이라면 STOP */
          if (user && !isExpired) return;

          const { req, cancel } = get_auth();
          canceler = cancel;

          const { data } = await req();
          authStore.setExpTime(momentTz().add(5, "minutes").toISOString()); //만료 시간 설정 (유저 정보를 새로 가져오도록)
          if (data.user) authStore.setUser(data.user);
          if (data.isBoarding) authStore.setIsBoarding(data.isBoarding);
        } catch (e) {
          console.log(e);
        } finally {
          setLoading(false);
        }
      })();

      return () => {
        if (canceler) canceler();
      };
    }, []);

    return (
      <Layout title={title} channel={channel} gnb={gnb}>
        {/* {isLoading && <>전체 로딩에 대한 처리 필요.</>} */}

        {!isLoading && (
          <>
            {repairStore.isRepair ? (
              <>
                {window.location.pathname === "/repair" ? (
                  <LoadableComp {...props} />
                ) : (
                  <Redirect to={"/repair"} />
                )}
              </>
            ) : (
              <>
                {authentication ? (
                  user ? (
                    <LoadableComp {...props} />
                  ) : (
                    //home으로 가는게 아니라 로그인 페이지로 보내야 하려나
                    <Redirect to={`/`} />
                  )
                ) : (
                  <LoadableComp {...props} />
                )}
              </>
            )}
          </>
        )}
      </Layout>
    );
  };

  return observer(AuthenticationCheck);
};

export default withAuth;
