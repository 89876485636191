export const Logo = () => {
  return (
    <svg
      width="103"
      height="28"
      viewBox="0 0 103 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M63.0331 7.10107C60.6431 7.10107 58.6931 9.00704 58.6931 11.3649C58.6931 13.7228 60.6331 15.6288 63.0331 15.6288C65.4331 15.6288 67.3731 13.7228 67.3731 11.3649C67.3631 9.00704 65.4231 7.10107 63.0331 7.10107ZM63.0331 12.5832C62.3531 12.5832 61.7931 12.0428 61.7931 11.3649C61.7931 10.6969 62.3431 10.1467 63.0331 10.1467C63.7131 10.1467 64.2731 10.687 64.2731 11.3649C64.2731 12.033 63.7131 12.5832 63.0331 12.5832Z"
        fill="#4FD3FC"
      />
      <path
        d="M45.0731 15.5304V7.20898H36.3931V20.8749H45.0631V17.9079H39.4931V15.5304H45.0731ZM41.9731 12.5634H39.4931V10.1858H41.9731V12.5634Z"
        fill="#4852E3"
      />
      <path
        d="M100.813 15.619V12.5734H95.2431V10.1467H100.813V7.10107H92.1431V15.619H100.813Z"
        fill="#4852E3"
      />
      <path
        d="M100.813 26.3571V23.3115H97.423L102.993 17.8293H92.143V20.875H95.533L89.963 26.3571H100.813Z"
        fill="#4852E3"
      />
      <path
        d="M100.813 1.73682H92.1431V4.78243H100.813V1.73682Z"
        fill="#4852E3"
      />
      <path d="M67.362 18.0012H58.692V21.0468H67.362V18.0012Z" fill="#4852E3" />
      <path d="M72.942 7.04688H69.842V15.5648H72.942V7.04688Z" fill="#4852E3" />
      <path
        d="M47.5431 26.3571H50.6431V20.875H56.2231V17.8293H47.5531L47.5431 26.3571Z"
        fill="#4852E3"
      />
      <path
        d="M47.5431 15.619H56.2131V12.5734H50.6331V10.1467H56.2131V7.10107H47.5431V15.619Z"
        fill="#4852E3"
      />
      <path
        d="M82.5431 7.20898V10.1858H86.5731V12.5634H83.1831L81.3031 10.7557V7.20898H78.2031V10.7557L74.7731 14.0469L76.9631 16.1493L79.7531 13.4771L82.5431 16.1493L83.1831 15.5304H86.5731V20.8749H89.6731V7.20898H82.5431Z"
        fill="#4852E3"
      />
      <path
        d="M17.35 7.21404H3.10001V21.214H0V4.17822H17.34L17.35 7.21404Z"
        fill="#4FD3FC"
      />
      <path
        d="M17.347 20.8732H20.737C21.597 23.0739 24.097 24.1743 26.337 23.3294C28.577 22.4845 29.697 20.0283 28.837 17.8276C27.977 15.6269 25.477 14.5266 23.237 15.3715C22.087 15.8038 21.177 16.6978 20.737 17.8276H17.347V20.8732ZM23.547 19.3504C23.547 18.6824 24.097 18.1322 24.787 18.1322C25.467 18.1322 26.027 18.6725 26.027 19.3504C26.027 20.0185 25.477 20.5687 24.787 20.5687C24.097 20.5785 23.537 20.0283 23.547 19.3504Z"
        fill="#4852E3"
      />
      <path
        d="M5.57703 19.3604C5.57703 21.7085 7.51703 23.6145 9.90703 23.6243C11.707 23.6243 13.317 22.5338 13.957 20.8832H17.347V17.8376H13.957C13.107 15.6369 10.597 14.5366 8.36703 15.3815C6.68703 16.0004 5.57703 17.592 5.57703 19.3604ZM8.67703 19.3604C8.67703 18.6924 9.22703 18.1422 9.91703 18.1422C10.597 18.1422 11.157 18.6825 11.157 19.3604C11.157 20.0285 10.607 20.5787 9.91703 20.5787C9.23703 20.5787 8.67703 20.0285 8.67703 19.3604Z"
        fill="#4FD3FC"
      />
      <path
        d="M34.42 4.16847V21.2043H31.32V7.20428H17.08V4.15869L34.42 4.16847Z"
        fill="#4852E3"
      />
    </svg>
  );
};
