/**
  * @version: 1.0.0
  * @createdAt: 2024-07-12 12:55:02
  * @createdBy: gimsoyeon-ui-Macmini.local
*/

import { ResGetAuth  } from './interface/res/get/Res.Get.Auth';
import { ReqPostAuthLogin  } from './interface/req/post/Req.Post.AuthLogin';
import { ResPostAuthLogin  } from './interface/res/post/Res.Post.AuthLogin';
import { ResGetAuthLogout  } from './interface/res/get/Res.Get.AuthLogout';

import { GET, POST, PUT, DELETE  } from "ms-ui";
class Auth {

/**
 * 쿠키가 있는지 없는지 확인, 있다면 정상적인 토큰을 가지고 있는지 확인, 정상적이지 않다면 쿠키를 말소, 응답으로 토큰을 내려준다
*/
get_auth(){
 return GET<ResGetAuth>({
   ckName: "USER",
   baseURL: `${window.__MSHUTTLE__PATH__.member_resource}`,
   url: `/auth`,
 });
}
/**
 * LoginGuard 미들웨어를 통과한 경우 JWT를 발급하여 쿠키에 넣는다.
*/
reg_auth_token(data: ReqPostAuthLogin){
 return POST<ResPostAuthLogin>({
   ckName: "USER",
   baseURL: `${window.__MSHUTTLE__PATH__.member_resource}`,
   url: `/auth`,
 }, data);
}
/**
 * 쿠키를 만료시킨다
*/
remove_auth_token(){
 return DELETE<ResGetAuthLogout>({
   ckName: "USER",
   baseURL: `${window.__MSHUTTLE__PATH__.member_resource}`,
   url: `/auth`,
 });
}
 
}

export const AuthRepository = new Auth();
export const { get_auth, reg_auth_token, remove_auth_token } = AuthRepository;
	