import { observer } from "mobx-react";
import { IScontr } from "ms_data";
import { useURI } from "ms_router";
import { useEffect } from "react";
import { useStores } from "src/store/global";

const RepairEventHandler = observer(() => {
  const { navigate } = useURI();
  const { repairStore } = useStores();

  //const listener = (e: CustomEvent<{ scontr: IScontr }>) => {
  const listener = (e: CustomEvent<Partial<IScontr>>) => {
    console.log("e: ", e);
    repairStore.setSchedule({ start: e.detail.start, end: e.detail.end });
    repairStore.setIsRepair(true);

    const pathname = window.location.pathname;
    if (pathname !== "/repair") {
      navigate("/repair", { replace: true });
    }
  };

  useEffect(() => {
    window.addEventListener("repair-event", listener as EventListener);

    return () => {
      window.removeEventListener("repair-event", listener as EventListener);
    };
  }, []);

  return null;
});

export default RepairEventHandler;
